<template>
  <div class="mobile-home flex-col align-center">
    <img class="home-bg" src="../../assets/img/h5Home_bg.png" alt="可搭吖">
    <Nav />
    <div class="banner">
      <img src="../../assets/img/banner.png" alt="可搭吖">
    </div>
    <div class="content1 flex-col align-center">
      <p class="txt1">女孩们的专属找搭子APP</p>
      <p class="txt2">轻松找到志同道合的姐妹搭子，一起嗨翻天！</p>
      <div class="btn-box justify-between align-center">
        <a class="btn-item justify-center align-center" :href="appleDownloadUrl">
          <img src="../../assets/img/app_store.png" alt="可搭吖">
          <span>App Store</span>
        </a>
        <div class="btn-item justify-center align-center" @click="downloadApk">
          <img src="../../assets/img/android.png" alt="可搭吖">
          <span>Android</span>
        </div>
      </div>
      <img class="wuxing" src="../../assets/img/wuxing.png" alt="可搭吖">
      <p class="txt3">轻松找到志同道合的姐妹搭子，一起嗨翻天！</p>
      <p class="txt4">安全私密，轻松找到兴趣相投的姐妹搭子！</p>
    </div>
    <div class="content2">
      <img src="../../assets/img/img5.png" alt="可搭吖">
    </div>
    <div :class="'content3_' + index" :style="'background-image: url(' + item.bg + ')'" v-for="(item, index) in list" :key="index">
      <img :src="item.icon" alt="可搭吖">
      <p class="title">{{ item.title }}</p>
      <p class="desc">{{ item.desc }}</p>
    </div>
    <div class="content4">
      <p>快加入我们，开启你的姐妹淘之旅！</p>
    </div>
    <goTop/>
    <Footer @downloadUrl="getDownloadUrl" />
  </div>
</template>
<script>
import Nav from './components/Nav'
import Footer from './components/Footer'
import goTop from './components/goTop.vue'
export default {
  name: 'MobileHome',
  components: {
    Nav,
    Footer,
    goTop
  },
  data() {
    return {
      appleDownloadUrl: '',
      androidDownloadUrl: '',
      androidAppVersion: '',
      list: [
        {
          bg: require('../../assets/img/img1.png'),
          icon: require('../../assets/img/img1_icon.png'),
          title: '找理想的搭子',
          desc: '基于用户的兴趣爱好进行智能匹配，推荐最可能成为好友的搭子，无论是城市探险、美食分享还是健身活动，您都能找到合适的伙伴。'
        },
        {
          bg: require('../../assets/img/img2.png'),
          icon: require('../../assets/img/img2_icon.png'),
          title: '多元化的聊天',
          desc: '不仅支持一对一的私密对话，还允许创建群聊，与多位朋友共同讨论、分享，使社交体验更加丰富多彩。'
        },
        {
          bg: require('../../assets/img/img3.png'),
          icon: require('../../assets/img/img3_icon.png'),
          title: '安全与隐私',
          desc: '我们高度重视用户的隐私和数据安全。所有的聊天数据都经过加密，确保只有聊天双方可以访问。'
        },
        {
          bg: require('../../assets/img/img4.png'),
          icon: require('../../assets/img/img4_icon.png'),
          title: '热门话题',
          desc: 'APP内定期推出不同的话题讨论，鼓励用户参与，增加交流的深度和广度，让您在享受社交乐趣的同时，也能获得知识和信息。'
        }
      ]
    }
  },
  mounted() {},
  computed: {

  },
  methods: {
    getDownloadUrl(data){
      if (data.kind == 1) {
        this.appleDownloadUrl = data.url
      } else {
        this.androidDownloadUrl = data.url
        this.androidAppVersion = data.version
      }
    },
    isAppleDevice() {
      return /iPhone|iPad|iPod/.test(navigator.userAgent);
    },
    isWeChatBrowser() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /micromessenger/.test(userAgent);
    },
    // 点击下载安卓app
    downloadApk() {
        // 安卓手机微信内打不开Blob，会提示“即将离开微信，前往浏览器打开”，但是会有问题，则执行普通的a标签下载
        if (!this.isAppleDevice() && this.isWeChatBrowser()) {
          let link = document.createElement('a');
          link.href = this.androidDownloadUrl;
          link.download = `kedaya_${this.androidAppVersion}.apk`; // 下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          fetch(this.androidDownloadUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const a = document.createElement("a");
              a.href = url;
              a.download = `kedaya_${this.androidAppVersion}.apk`; // 设置下载文件的名称
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            });
        }
      },
  }
}
</script>

<style lang="scss" scoped>
.mobile-home {
  width: 375rem;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  padding-top: 50rem;
  .home-bg {
    width: 100%;
    height: 1012rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .banner {
    width: 100%;
    height: 317rem;
    margin-top: 24rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content1 {
    width: 343rem;
    margin-top: 36rem;
    .txt1 {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 16rem;
      color: #333333;
      line-height: 19rem;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
    .txt2 {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12rem;
      color: #999999;
      line-height: 14rem;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin-top: 8rem;
    }
    .btn-box {
      margin-top: 20rem;
      width: 255rem;
      .btn-item {
        width: 120rem;
        height: 36rem;
        border-radius: 9rem;
        border: 1px solid #333333;
        img {
          width: 21rem;
          height: 21rem;
        }
        span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 11rem;
          color: #333333;
          line-height: 12rem;
          text-align: center;
          font-style: normal;
          text-transform: none;
          margin-left: 12rem;
        }
      }
    }
    .wuxing {
      width: 34rem;
      height: 36rem;
      margin-top: 48rem;
    }
    .txt3 {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 16rem;
      color: #333333;
      line-height: 19rem;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin-top: 20rem;
    }
    .txt4 {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12rem;
      color: #999999;
      line-height: 14rem;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin-top: 8rem;
    }
  }
  .content2 {
    width: 100%;
    height: 216rem;
    margin-top: 20rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content3_0,
  .content3_1,
  .content3_2,
  .content3_3 {
    width: 343rem;
    height: 248rem;
    margin-top: 36rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    
    img {
      width: 36rem;
      height: 36rem;
      margin: 20rem 0 0 20rem;
    }
    .title {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 16rem;
      color: #333333;
      line-height: 19rem;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin: 16rem 0 0 20rem;
    }
    .desc {
      width: 180rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12rem;
      color: #666666;
      line-height: 19rem;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin: 67rem 0 0 20rem;
    }
  }
  .content3_0 {
    box-shadow: 8rem 24rem 59rem 0rem rgba(0,128,128,0.1);
  }
  .content3_1 {
    box-shadow: 8rem 24rem 59rem 0rem rgba(180,166,249,0.1);
  }
  .content3_2 {
    box-shadow: 8rem 24rem 59rem 0rem rgba(86,152,248,0.1);
  }
  .content3_3 {
    box-shadow: 8rem 24rem 59rem 0rem rgba(250,136,125,0.1);
  }
  .content4 {
    width: 100%;
    height: 486rem;
    background-image: url('../../assets/img/img6.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 48rem;
    p {
      width: 100%;
      text-align: center;
      margin-top: 42rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 16rem;
      color: #333333;
      line-height: 19rem;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }
  }
}
</style>